li {
  display: inline-block;    
}

.nav{
  display: flex;
  align-items: flex-end;
}

.blink
{
    animation:5s blinker linear infinite;
    -webkit-animation:5s blinker linear infinite;
    -moz-animation:5s blinker linear infinite;
    color: green;
    font-weight: bold;
}

@-moz-keyframes blinker
{  
    25% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@-webkit-keyframes blinker
{  
    25% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@keyframes blinker
{  
    25% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
 }
